
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import makeStyles from '@mui/styles/makeStyles';

import styles from 'assets/jss/nextjs-material-dashboard-pro/components/footerStyle.js';

export default function Footer(props) {
    const useStyles = makeStyles(styles);
    const classes = useStyles();
    const { fluid, white } = props;
    var container = cx({
        [classes.container]: !fluid,
        [classes.containerFluid]: fluid,
        [classes.whiteColor]: white,
    });
    var anchor =
    classes.a +
    cx({
        [' ' + classes.whiteColor]: white,
    });
    var block = cx({
        [classes.block]: true,
        [classes.whiteColor]: white,
    });
    return (
        <footer className={classes.footer}>
            <div className={container}>
                {/* <div className={classes.left}>
                    <List className={classes.list}>
                        <ListItem className={classes.inlineBlock}>
                            <a href="#home" className={block}>
                Feedback
                            </a>
                        </ListItem>
                    </List>
                </div> */}
                <p className={classes.right}>
          &copy; {1900 + new Date().getYear()} Created by {' '}
                    <a
                        href="https://frinzelapuz.vercel.app"
                        className={anchor}
                        target="_blank" rel="noreferrer"
                    >
           Frinze Erin Lapuz
                    </a> {' '}
          on behalf of Dr. Cristina Vulpe
                </p>
            </div>
        </footer>
    );
}

Footer.propTypes = {
    fluid: PropTypes.bool,
    white: PropTypes.bool,
};
