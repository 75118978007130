import React, {useState, useEffect} from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';

import makeStyles from '@mui/styles/makeStyles';
import InputAdornment from '@mui/material/InputAdornment';
import Icon from '@mui/material/Icon';

// @mui/icons-material
import Email from '@mui/icons-material/Email';
// import LockOutline from "@mui/icons-material/LockOutline";

// layout for this page
import Auth from 'layouts/Auth.js';
// core components
import GridContainer from 'components/MaterialDashboard/Grid/GridContainer.js';
import GridItem from 'components/MaterialDashboard/Grid/GridItem.js';
import CustomInput from 'components/MaterialDashboard/CustomInput/CustomInput.js';
import Button from 'components/MaterialDashboard/CustomButtons/Button.js';
import Card from 'components/MaterialDashboard/Card/Card.js';
import CardBody from 'components/MaterialDashboard/Card/CardBody.js';
import CardHeader from 'components/MaterialDashboard/Card/CardHeader.js';
import CardFooter from 'components/MaterialDashboard/Card/CardFooter.js';

import styles from 'assets/jss/nextjs-material-dashboard-pro/views/loginPageStyle.js';

// Own Code
import {handleChangeStandardGenerator} from 'components/Custom/FormHandlers';
import { signInLocal, useSignIn } from 'actions/auth';

function LoginPage() {
    const useStyles = makeStyles(styles);
    const dispatch = useDispatch();
    const router = useRouter();
    const classes = useStyles();

    const [formState, setFormState] = useState({
        'email':'',
        'password':''
    });

    // Automatically logins if user has details in localStorage
    const authState = useSignIn();

    const handleSubmit = (event) =>{
        event.preventDefault();
        dispatch(signInLocal(formState));
    };

    // Any change on auth state will evaluate redirection
    useEffect(() =>{
        if(authState.user){
            // User is valid, hence redirecting
            console.info('Redirecting to Dashboard');
            router.push('/private/user/monitoring-technologies');
        }
    }, [authState]);
   
    return (
        <div className={classes.container}>
            <GridContainer justifyContent="center">
                <GridItem xs={12} sm={6} md={4}>
                    <form onSubmit={handleSubmit}>
                        <Card>
                            <CardHeader
                                className={`${classes.cardHeader} ${classes.textCenter}`}
                                color="primary"
                            >
                                <h4 className={classes.cardTitle}>Log in</h4>
                                {/* <div className={classes.socialLine}>
                                    {[
                                        'fab fa-google-plus',
                                    ].map((prop, key) => {
                                        return (
                                            <Button
                                                color="transparent"
                                                justIcon
                                                key={key}
                                                className={classes.customButtonClass}
                                            >
                                                <i className={prop} />
                                            </Button>
                                        );
                                    })}
                                </div> */}
                            </CardHeader>
                            <CardBody>
                                <CustomInput
                                    labelText="Email"
                                    id="email"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Email className={classes.inputAdornmentIcon} />
                                            </InputAdornment>
                                        ),
                                        onChange: handleChangeStandardGenerator(formState,setFormState)
                                    }}
                                />
                                <CustomInput
                                    labelText="Password"
                                    id="password"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                                                </Icon>
                                            </InputAdornment>
                                        ),
                                        type: 'password',
                                        autoComplete: 'off',
                                        onChange: handleChangeStandardGenerator(formState,setFormState)
                                    }}
                                />
                            </CardBody>
                            <CardFooter className={classes.justifyContentCenter}>
                                <Button color="primary" simple size="lg" block type="submit">
                  Submit
                                </Button>
                            </CardFooter>
                        </Card>
                    </form>
                </GridItem>
            </GridContainer>
        </div>
    );
}

LoginPage.layout = Auth;

export default LoginPage;
