import {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { feathersClient } from 'store/feathersClient';


export const useSignIn = () =>{
    // Custom Hook that will automatically dispatch a signIn if userState is null
    const authState = useSelector(state => state.auth);
    const dispatch = useDispatch();
    useEffect(() =>{
        if(!authState.user){
            dispatch(signInProgress());
            dispatch(signIn());
        }
    }, []);
    return authState;
};

// Returns a function that has access to dispatch and getState
export const signIn = (isUserUpdate) => async (dispatch, getState) => {
    try {
        // Force Update on User update
        const forceUpdate = isUserUpdate || false;
        const loginDetails = await feathersClient.reAuthenticate(forceUpdate);
        return dispatch({
            type: 'SIGNIN_SUCCESS',
            ...loginDetails
        });
    }
    catch (error) {
        // Cant Authenticate
        // TODO: Insert Set Login Provider to Null
        return dispatch({
            type: 'SIGNIN_ERROR',
            error
        });
    }
};

// Dispatches a signal to indicate "In progress Sign In"
export const signInProgress = () => async(dispatch, getState) => {
    return dispatch({
        type:'SIGNIN_INPROGRESS'
    });
};

export const signInLocal = (credentials) => async (dispatch, getState) => {
    // Dispatcher function that allows login using local
    // Note: The credentials object needs to have "email" and "password" as per the feathers auth
    try {
        const loginDetails = await feathersClient.authenticate({
            ...credentials,
            strategy: 'local'
        });
        return dispatch({
            type: 'SIGNIN_SUCCESS',
            ...loginDetails
        });
    }
    catch (error) {
        // Cant Authenticate
        return dispatch({
            type: 'SIGNIN_ERROR',
            error
        });
    }
};


export const signOut = () => async (dispatch, getState) => {
    await feathersClient.logout();
    return dispatch({
        type: 'SIGNOUT_SUCCESS'
    });
};