import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { useRouter } from 'next/router';
import makeStyles from '@mui/styles/makeStyles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Hidden from '@mui/material/Hidden';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

// @mui/icons-material
import Dashboard from '@mui/icons-material/Dashboard';
import Menu from '@mui/icons-material/Menu';
import Fingerprint from '@mui/icons-material/Fingerprint';


import Button from 'components/MaterialDashboard/CustomButtons/Button';

import styles from 'assets/jss/nextjs-material-dashboard-pro/components/authNavbarStyle.js';

export default function AuthNavbar(props) {
    const [open, setOpen] = React.useState(false);
    // used for checking current route
    const router = useRouter();
    const handleDrawerToggle = () => {
        setOpen(!open);
    };
    // verifies if routeName is the one active (in browser input)
    const activeRoute = (routeName) => {
        return router.route.indexOf(routeName) > -1 ? true : false;
    };
    const useStyles = makeStyles(styles);
    const classes = useStyles();
    const { color, brandText } = props;
    const appBarClasses = cx({
        [' ' + classes[color]]: color,
    });
    var list = (
        <List className={classes.list}>
            <ListItem className={classes.listItem}>
                <Link href={'/'}>
                    <a className={classes.navLink}>
                        <Dashboard className={classes.listItemIcon} />
                        <ListItemText
                            primary={'Homepage'}
                            disableTypography={true}
                            className={classes.listItemText}
                        />
                    </a>
                </Link>
            </ListItem>
            <ListItem className={classes.listItem}>
                <Link href={'/login'}>
                    <a
                        className={cx(classes.navLink, {
                            [classes.navLinkActive]: activeRoute('/login'),
                        })}
                    >
                        <Fingerprint className={classes.listItemIcon} />
                        <ListItemText
                            primary={'Login'}
                            disableTypography={true}
                            className={classes.listItemText}
                        />
                    </a>
                </Link>
            </ListItem>
        </List>
    );
    return (
        <AppBar position="static" className={classes.appBar + appBarClasses}>
            <Toolbar className={classes.container}>
                <Hidden mdDown>
                    <div className={classes.flex}>
                        <Button href="#" className={classes.title} color="transparent">
                            {brandText}
                        </Button>
                    </div>
                </Hidden>
                <Hidden mdUp>
                    <div className={classes.flex}>
                        <Button href="#" className={classes.title} color="transparent">
              AMIRA WEBSITE
                        </Button>
                    </div>
                </Hidden>
                <Hidden mdDown>{list}</Hidden>
                <Hidden mdUp>
                    <Button
                        className={classes.sidebarButton}
                        color="transparent"
                        justIcon
                        aria-label="open drawer"
                        onClick={handleDrawerToggle}
                    >
                        <Menu />
                    </Button>
                </Hidden>
                <Hidden mdUp>
                    <Hidden mdUp>
                        <Drawer
                            variant="temporary"
                            anchor={'right'}
                            open={open}
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            onClose={handleDrawerToggle}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                        >
                            {list}
                        </Drawer>
                    </Hidden>
                </Hidden>
            </Toolbar>
        </AppBar>
    );
}

AuthNavbar.propTypes = {
    color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger']),
    brandText: PropTypes.string,
};
