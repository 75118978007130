import React from 'react';
import { useRouter } from 'next/router';
import makeStyles from '@mui/styles/makeStyles';

// core components
import AuthNavbar from 'components/MaterialDashboard/Navbars/AuthNavbar.js';
import Footer from 'components/MaterialDashboard/Footer/Footer.js';


import styles from 'assets/jss/nextjs-material-dashboard-pro/layouts/authStyle.js';

import login from 'assets/img/ug1.jpg';
import error from 'assets/img/tails2.jpg';

export default function Pages(props) {
    const { children, ...rest } = props;
    // used for checking current route
    const router = useRouter();
    // ref for the wrapper div
    const wrapper = React.createRef();
    // styles
    const useStyles = makeStyles(styles);
    const classes = useStyles();
    React.useEffect(() => {
        document.body.style.overflow = 'unset';
        // Specify how to clean up after this effect:
        return function cleanup() {};
    });
    const getBgImage = () => {
        if (router.route.indexOf('/login') !== -1) {
            return login;
        }
        if (router.route.indexOf('/error') !== -1) {
            return error;
        }
    };
    return (
        <div>
            <AuthNavbar brandText={'Tailings Monitoring'} {...rest} />
            <div className={classes.wrapper} ref={wrapper}>
                <div
                    className={classes.fullPage}
                    style={{ backgroundImage: 'url(' + getBgImage() + ')' }}
                >
                    {children}
                    <Footer white />
                </div>
            </div>
        </div>
    );
}
